import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DollarIcon from 'assets/custom-icons/DollarIcon';
import {
  FormItem,
  Col,
  MortgageQuestionContentWrapper,
  MortgageTitle,
  CustomError,
  InputNumber,
  Paragraph,
  Row,
} from 'components/atoms';
import { getMortgageData, getMortgageStep } from 'app/services/selector-helpers';

const PercentText = styled(Paragraph)`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: -10px !important;

  @media screen and (max-width: 992px) {
    font-size: 24px;
    text-align: center;
  }
`;

const PropertyText = styled(Paragraph)`
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 992px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const downPaymentSetting = homeCost => [
  {
    min: -9999999,
    max: 120999,
    minPercent: 0,
    minimum() {
      return -99999999;
    },
    condition(value) {
      return false;
    },
    percent(value) {
      return (value * 100) / homeCost || 0;
    },
    warningText: 'Ratebuzz can not help on homes less than $121,000 at this time.',
  },
  {
    min: 120999,
    max: 500000,
    minPercent: 5,
    minimum() {
      return (homeCost * this.minPercent) / 100;
    },
    condition(value) {
      return value >= this.minimum();
    },
    percent(value) {
      return (value * 100) / homeCost || 0;
    },
    warningText:
      'For a purchase price of $500,000 or less, the minimum down payment is 5% of property value',
  },
  {
    min: 500000,
    max: 1499999,
    minPercent: 10,
    minimum() {
      return this.min * 0.05 + (homeCost - this.min) * (this.minPercent / 100);
    },
    condition(value) {
      return value >= this.minimum();
    },
    percent(value) {
      return (value * 100) / homeCost || 0;
    },
    warningText:
      'Minimum down payment is 5% for any homes less than $500.000 and 10% for the remaining balance over $500.000',
  },
  {
    min: 1499999,
    max: 9999999,
    minPercent: 20,
    minimum() {
      return (homeCost * this.minPercent) / 100;
    },
    condition(value) {
      return value >= this.minimum();
    },
    percent(value) {
      return (value * 100) / homeCost || 0;
    },
    warningText:
      'For any properties that have a purchase price of $1,500,000 or more, the minimum downpayment must be 20%',
  },
];

export const calculateData = (conditions, value, homeCost) => {
  const options = conditions(homeCost).find(el => el.min < homeCost && el.max >= homeCost);
  return {
    isValid: options?.condition(value),
    percent: options?.percent(value),
    minimum: options?.minimum(),
    warningText: options?.warningText,
  };
};

const DownPayment = ({ form, mortgageType }) => {
  const mortgageStep = useSelector(getMortgageStep);
  const mortgageData = useSelector(state => getMortgageData(state, mortgageType));
  const purchasePrice = mortgageData[mortgageStep - 1]?.purchasePrice || 0;
  const downPayment = mortgageData[mortgageStep]?.downPayment || 0;
  const [currentPrice, setCurrentPrice] = useState(0);

  const { isValid, percent, minimum, warningText } = calculateData(
    downPaymentSetting,
    currentPrice,
    purchasePrice
  );

  useEffect(() => {
    if (downPayment) {
      setCurrentPrice(Math.ceil(downPayment));
      form.setFieldsValue({ downPayment: Math.ceil(downPayment) });
    } else {
      setCurrentPrice(Math.ceil(minimum));
      form.setFieldsValue({ downPayment: Math.ceil(minimum) });
    }
  }, []);

  return (
    <>
      <MortgageQuestionContentWrapper>
        <MortgageTitle>How much is your down payment?</MortgageTitle>
        <Row gutter={30}>
          <Col xs={24} md={24} lg={15}>
            <FormItem
              name='downPayment'
              label='Down payment'
              rules={[
                () => ({
                  validator(_, value) {
                    if (isValid) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(<CustomError text={warningText} />);
                  },
                }),
              ]}
            >
              <InputNumber
                max={mortgageData[mortgageStep - 1]?.purchasePrice || 0}
                prefix={<DollarIcon />}
                controls={false}
                type='tel'
                isPrice={true}
                onChange={setCurrentPrice}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={24} lg={9}>
            <PercentText mb={0}>{percent?.toFixed(2)} %</PercentText>
            <PropertyText mb={0}>of the property price</PropertyText>
          </Col>
        </Row>
      </MortgageQuestionContentWrapper>
    </>
  );
};

export default DownPayment;
